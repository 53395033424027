import * as React from 'react';
import * as ui from '@chakra-ui/react';
import { PositionableSticker } from '@lib/types';

function placementToPosition(placement: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right') {
	if (placement == 'top-left') {
		return {
			top: '0%',
			left: '0%',
		};
	} else if (placement == 'top-right') {
		return {
			top: '0%',
			left: '100%',
		};
	} else if (placement == 'bottom-right') {
		return {
			top: '100%',
			left: '100%',
		};
	} else if (placement == 'bottom-left') {
		return {
			top: '100%',
			left: '0%',
		};
	}
}

export const FeedItemSticker: React.FC<{ stickerUrl: string }> = (props) => {
	return <ui.Image src={props.stickerUrl} alt="" />;
};

type FeedItemStickerPlacementOptions = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
export const FeedItemStickerPlacement: React.FC<{ sticker: React.ReactNode; placement: FeedItemStickerPlacementOptions }> = (props) => {
	const { top, left } = placementToPosition(props.placement);
	return (
		<ui.Box pos="absolute" top={top} left={left} boxSize="75px" transform="translate(-50%, -50%)" zIndex="tooltip">
			{props.sticker}
		</ui.Box>
	);
};

export const FeedItemStickers: React.FC<{ stickers: PositionableSticker[] }> = (props) => {
	return (
		<>
			{props.stickers?.map((sticker) => (
				<FeedItemStickerPlacement
					key={sticker._key}
					placement={sticker.stickerPosition as FeedItemStickerPlacementOptions}
					sticker={<FeedItemSticker stickerUrl={sticker.sticker.icon.url} />}
				/>
			))}
		</>
	);
};
