import clone from 'lodash/clone';
import React from 'react';

export const replaceNewlinesWithLineBreaks = (text: string, ignoreLast = false) => {
	const lines = text.split('\n');
	return lines.map((item, key) => {
		const isLast = key + 1 == lines.length;
		return (
			<React.Fragment key={key}>
				{item}
				{!ignoreLast ? <br /> : !isLast && <br />}
			</React.Fragment>
		);
	});
};

export const isBrowser = () => typeof window !== 'undefined';

export const isValidKey = (obj: Record<string, unknown>, value: string): value is keyof typeof obj => {
	return value in obj;
};

export const getFullSlug = (slug: string, type: string) => {
	if (!slug) return '/';
	if (type == 'post') return `/news/${slug}`;
	else if (type == 'project') return `/work/${slug}`;
	else if (type == 'sandbox') return `/sandbox/${slug}`;
	// mk: we changed this intentionally. this is used by Feed to determine
	// the full slug of an item being imported to Sandbox/Research
	else if (type == 'research') return `/sandbox/${slug}`;
	else return `/${slug}`;
};

export const setBodyClassModal = (isModal: boolean) => {
	const body = document.querySelector('body');
	isModal ? body?.classList.add('modal-open') : body?.classList.remove('modal-open');
	return;
};

export const hex2rgba = (hex: string, alpha: number) => {
	const rgb = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16));
	if (rgb) return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${alpha})`;
};

export function cloneComponent<C extends React.FC>(component: C, defaultProps?: C['defaultProps']) {
	const cloned = clone(component);
	if (defaultProps) cloned.defaultProps = defaultProps;
	return cloned;
}

export const easeIn = (t: number) => t * t;
export const easeInOut = (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1);
export const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);
export const map = (value: number, x1: number, y1: number, x2 = 0, y2 = 1) =>
	value === 0 ? x2 : ((clamp(value, x1, y1) - x1) * (y2 - x2)) / (y1 - x1) + x2;

export const getWindowScrollY = () => (isBrowser ? window.scrollY : 0);
