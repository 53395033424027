import { useState, useEffect } from 'react';

const useInteraction = (ref: React.MutableRefObject<HTMLDivElement>, callback: () => void) => {
	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIntersecting(entry.isIntersecting);
			},
			{
				threshold: 0.25,
				root: null,
				rootMargin: '1000px',
			},
		);

		observer.observe(ref.current);
		// Remove the observer as soon as the component is unmounted
		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		if (callback && isIntersecting) callback();
	}, [isIntersecting, callback]);

	return isIntersecting;
};

export default useInteraction;
