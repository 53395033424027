import * as React from 'react';
import { ExternalLinkWrapper } from '@components/ExternalLink';
import { FeedItemMeta } from './FeedItemMeta';
import { Link } from '@components/Link';
import { Skeleton, Box } from '@chakra-ui/react';

export interface FeedItemInstagramProps {
	body: string;
	url: string;
}

export const FeedItemInstagram: React.FC<FeedItemInstagramProps> = (props) => {
	const [response, setResponse] = React.useState<{ thumbnail_url: string; author_name: string }>();

	React.useEffect(() => {
		const requestURL = `https://graph.facebook.com/v9.0/instagram_oembed?url=${props.url.replace(
			/(\/+)$/,
			'',
		)}/&access_token=${784066932377786}|${'ffeee57c2cc48e68faa0255b6f8701ae'}`;

		fetch(requestURL)
			.then((response) => {
				if (response.ok) {
					if (response.status === 204) return response;

					return response.text().then((text) => {
						try {
							return JSON.parse(text);
						} catch (e) {
							throw new Error(`Body could not be parsed as JSON: ${text}`);
						}
					});
				}
				return response.text().then(() => {
					// throw new RequestError(response, text)
				});
			})
			.then((data) => setResponse(data));
	}, [props.url]);

	if (!response) {
		return <Skeleton height="200px" />;
	}

	return (
		<Box className="grid-instagram">
			<ExternalLinkWrapper href={props.url}>
				<img src={response.thumbnail_url} alt="" />
			</ExternalLinkWrapper>

			<FeedItemMeta
				category="Instagram"
				title={<ExternalLinkWrapper href={`https://www.instagram.com/${response.author_name}`}>@{response.author_name}</ExternalLinkWrapper>}
				body={props.body}
			/>
		</Box>
	);
};
