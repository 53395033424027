import * as React from 'react';
import { getFullSlug } from '@lib/utils';
import { GridItem, Box, Text } from '@chakra-ui/react';

import { FeedItemComponent, FeedItemComponentProps } from './FeedItemComponent';
import { FeedItemExternalLink, FeedItemExternalLinkProps } from './FeedItemExternalLink';
import { FeedItemInstagram, FeedItemInstagramProps } from './FeedItemInstagram';
import { FeedItemPost, FeedItemPostProps } from './FeedItemPost';
import { FeedItemProject, FeedItemProjectProps } from './FeedItemProject';
import { FeedItemProjectVideoModule, FeedItemProjectVideoModuleProps } from './FeedItemProjectVideoModule';
import { FeedItemStickers } from './FeedItemStickers';
import { GridCellContext } from '@components/GridCellContext';
import { GridItemProps } from '@chakra-ui/react';
import { MuxVideo } from '@components/modules/VideoModule';
import { getGridColumn, LayoutItem, layoutItemsToRows } from '@lib/layout';
import { inversePadding } from '@theme';
import useIntersection from '@hooks/useIntersection';

export { FeedItemComponent, FeedItemPost, FeedItemProject, FeedItemExternalLink, FeedItemProjectVideoModule, FeedItemInstagram, FeedItemStickers };

export type FeedItemType =
	| FeedItemComponentProps
	| FeedItemPostProps
	| FeedItemProjectProps
	| FeedItemExternalLinkProps
	| FeedItemProjectVideoModuleProps
	| FeedItemInstagramProps;

export type FeedItem = FeedItemType & LayoutItem & { _type: string };

interface FeedProps {
	/**
	 * If true, will show category types alongside content instead of secondary infomation.
	 * Mainly used for showing "Work", "Research" along side projects on the hompage
	 * main feed and dates for projects on the research feed.
	 **/
	isGeneric?: boolean;
	isUniformHeight?: boolean;
	items: FeedItem[];
	preventContentOverlapWithLogoAndMenu?: boolean;
	showExcerpt?: boolean;
	showMeta?: boolean;
}

export const Feed: React.FC<FeedProps> = (props) => {
	let span2LeftRightFlip = false;
	const endOfFeedRef = React.useRef<HTMLDivElement>();

	const [numItems, setNumItems] = React.useState(4);

	const onLoadMore = React.useCallback(() => {
		setNumItems((numItems) => numItems + 4);
	}, [setNumItems]);

	const isVisible = useIntersection(endOfFeedRef, onLoadMore);

	const slicedItems = props.items.slice(0, numItems);

	const rows = layoutItemsToRows(slicedItems);

	return (
		<>
			{rows.map((row, rowI) => {
				let rowContentIndex = 0;
				const rowContentCount = row.items.filter((item) => item._type != 'feedSpacer').length;

				return (
					<React.Fragment key={rowI}>
						{row.items?.map((item) => {
							let inner: React.ReactNode = null;

							const gridItemProps: GridItemProps = {
								gridColumn: {
									base: '1/-1',
									sm: getGridColumn({ gridColumn: item.gridColumn, gridSpan: item.gridSpan }),
									md: getGridColumn({ gridColumn: item.gridColumn, gridSpan: item.gridSpan }),
								},
							};

							if (rowContentIndex == 0 && rowContentCount == 2) {
								// first item in a row with other items
								gridItemProps.gridColumn['sm'] = props.preventContentOverlapWithLogoAndMenu ? '3/span 3' : 'span 4';
							} else if (rowContentCount == 2) {
								// one item in a row of 2
								gridItemProps.gridColumn['sm'] = props.preventContentOverlapWithLogoAndMenu ? 'span 3' : 'span 4';
							} else {
								// only item in a row
								gridItemProps.gridColumn['sm'] = props.preventContentOverlapWithLogoAndMenu ? '3/span 6' : 'span 8';
							}

							if (item.gridSpan == 'span2' && item._type != 'feedSpacer') {
								gridItemProps.gridColumn['base'] = span2LeftRightFlip ? '3/span 6' : '1/span 6';
								span2LeftRightFlip = !span2LeftRightFlip;
							}

							if (item.gridSpan == 'full') {
								gridItemProps.mx = inversePadding;
							}

							if (item._type == 'feedText') {
								return (
									<GridItem
										key={item._key}
										fontSize={{ base: '1.2em', sm: '1.44em' }}
										gridColumn={{ base: '1/-1', md: '1/span 5', lg: '2/span 4' }}
										position="relative"
									>
										<Text mt={'calc((1 - 1.4) * 1em)'}>{item.text}</Text>
									</GridItem>
								);
							}

							if (item._type == 'feedSpacer') {
								return (
									<GridItem
										display={{ base: 'none', sm: props.preventContentOverlapWithLogoAndMenu ? 'unset' : 'none', md: 'unset' }}
										key={item._key}
										gridColumn={{ base: 'span 2', md: getGridColumn({ gridSpan: item.gridSpan }) }}
										position="relative"
									></GridItem>
								);
							}

							switch (item._type) {
								case 'feedLink':
									if (item.component) {
										inner = (
											<FeedItemComponent
												// category={'Research'}
												height={item.component.componentRef.height}
												href={getFullSlug(item.link.slug, item.link._type)}
												id={item.component.componentRef.id}
												showBody={props.showExcerpt}
												showCategory={props.isGeneric}
												showDate={!props.isGeneric && props.showMeta}
												text={item.link?.excerpt}
												title={item.link?.title}
												width={item.component.componentRef.width}
											/>
										);
									} else if (item.link._type == 'post') {
										const post = item.link;

										inner = (
											<FeedItemPost
												body={post.excerpt}
												category={'News'}
												href={getFullSlug(post.slug, post._type)}
												image={item.image?.asset || post.imageCover}
												showBody={props.showExcerpt}
												showCategory={props.isGeneric}
												showDate={!props.isGeneric && props.showMeta}
												title={post.title}
											/>
										);
									} else if (item.link._type == 'project') {
										const project = item.link;
										inner = (
											<FeedItemProject
												body={project.excerpt}
												category={'Work'}
												image={item.image?.asset || project.imageFeed || project.imageGrid || project.imageCover}
												isUniformHeight={props.isUniformHeight}
												showBody={props.showExcerpt}
												showCategory={props.isGeneric}
												showDate={!props.isGeneric && props.showMeta}
												slug={getFullSlug(project.slug, project._type)}
												title={project.title}
												video={item.video}
											/>
										);
									} else if (item.link._type == 'research') {
										const project = item.link;

										inner = (
											<>
												<FeedItemProject
													body={project.excerpt}
													category={'Research'}
													image={item.image?.asset || project.imageFeed || project.imageGrid || project.imageCover}
													isUniformHeight={props.isUniformHeight}
													showBody={props.showExcerpt}
													showCategory={props.isGeneric}
													showDate={!props.isGeneric && props.showMeta}
													slug={getFullSlug(project.slug, project._type)}
													title={project.title}
													video={item?.video}
												/>
											</>
										);
									}
									break;
								case 'feedExternalLink':
									// return <GridItemWrapper size={size}></GridItemWrapper>
									inner = (
										<FeedItemExternalLink
											body={item.excerpt}
											category=""
											image={item.image}
											title={item.title}
											url={item.url}
											video={item?.video}
										/>
									);
									break;
								case 'feedVideo':
									inner = <MuxVideo muxVideoAsset={item.video?.muxVideo?.asset} background autoPlay />;
									break;
								case 'feedInstagram':
									inner = <FeedItemInstagram url={item.url} body={item.excerpt} />;
									break;
							}

							if (!inner) return null;

							rowContentIndex++;

							return (
								<GridCellContext.Provider value={{ gridSpan: item.gridSpan }} key={item._key}>
									<GridItem {...gridItemProps} position="relative">
										{inner}
										{/* <ui.Box
                      as="pre"
                      zIndex="99999"
                      color="red.500"
                      bg="white"
                      opacity={0.8}
                      position="absolute"
                      top="0"
                      left="0"
                      fontSize="8px"
                      p="2"
                    >
                      {JSON.stringify({ ...gridItemProps, type: item._type, debug: item.debug, rowCount: row.count }, undefined, 2)}
                    </ui.Box> */}
										{item.stickers && <FeedItemStickers stickers={item.stickers} />}
									</GridItem>
								</GridCellContext.Provider>
							);
						})}
					</React.Fragment>
				);
			})}
			<Box ref={endOfFeedRef} gridColumn="1/-1"></Box>
		</>
	);
};

Feed.defaultProps = {
	isGeneric: true,
	showExcerpt: true,
	showMeta: true,
};
