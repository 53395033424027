import * as React from 'react';
import { GridCellSize } from '@components/Grid';
import { forwardRef, GridProps, Grid, GridItem, Box, Text } from '@chakra-ui/react';
import { padding } from '@theme';
import { useGridCellContext } from '@components/GridCellContext';

export interface FeedItemMetaProps {
	body?: React.ReactNode;
	category?: string;
	date?: string;
	showBody?: boolean;
	showCategory?: boolean;
	showDate?: boolean;
	size?: GridCellSize;
	title?: React.ReactNode;
}

export const FeedItemMeta = forwardRef<Omit<GridProps, 'title'> & FeedItemMetaProps, 'div'>(
	({ title, body, category, showCategory, date, showDate, showBody, ...refProps }, ref) => {
		const gridCellContext = useGridCellContext();

		const orientation = React.useMemo(() => (gridCellContext?.gridSpan == 'span2' ? 'vertical' : 'horizontal'), [gridCellContext?.gridSpan]);

		const repeatCols = React.useMemo(() => {
			if (showCategory && orientation == 'vertical') return 1;
			if (gridCellContext?.gridSpan == 'span3') return 2;
			if (gridCellContext?.gridSpan == 'span4') return 2;
			if (gridCellContext?.gridSpan == 'span6') return 3;
			if (gridCellContext?.gridSpan == 'span8') return 4;
			return 1;
		}, [gridCellContext?.gridSpan, showCategory, orientation]);

		const extra = [showCategory && category, showDate && date].filter((item) => !!item);

		const isHorizontal = orientation == 'horizontal';

		return (
			<Grid
				className="grid-meta"
				columnGap={4}
				just="true"
				mt={5}
				px={(gridCellContext?.gridSpan == 'span8' || gridCellContext?.gridSpan == 'full') && padding}
				ref={ref}
				rowGap={{ base: 0, sm: 4 }}
				templateColumns={{ base: '1fr', sm: '1fr', md: `repeat(${repeatCols}, 1fr)` }}
				{...refProps}
			>
				{extra.length > 0 && isHorizontal && (
					<GridItem gridColumn="span 1" mb={4}>
						{extra.map((item, i) => (
							<Box key={i}>{item}</Box>
						))}
					</GridItem>
				)}
				<GridItem gridColumn={{ base: '1/-1', md: isHorizontal ? `${extra.length > 0 ? Math.max(extra.length + 1, 2) : 1}/-1` : '1/-1' }}>
					{title && (
						<Text as="h2" mb={2}>
							{title}
						</Text>
					)}
					{body && showBody && (
						<Text as="p" opacity={0.5}>
							{body}
						</Text>
					)}
				</GridItem>
			</Grid>
		);
	},
);

FeedItemMeta.defaultProps = {
	showCategory: true,
	showBody: true,
};
