import * as React from 'react';
import GridAlignmentWrapper from '@components/GridAlignmentWrapper';
import StyledSanityImage from '@components/SanityImage';
import { ExternalLinkWrapper } from '@components/ExternalLink';
import { FeedItemMeta } from './FeedItemMeta';
import { FigureImage } from '@lib/types';
import { Grid, GridItem } from '@chakra-ui/react';
import { MuxVideo, MuxVideoData } from '@components/modules/VideoModule';
import { SanityImageAsset } from '@sanity/asset-utils';

export interface FeedItemExternalLinkProps {
	body: string;
	category: string;
	image: FigureImage;
	isUniformHeight?: boolean;
	title: string;
	url: string;
	video?: {
		muxVideo?: {
			asset?: MuxVideoData;
		};
		thumbnail?: {
			asset?: SanityImageAsset;
		};
	};
}

export const FeedItemExternalLink: React.FC<FeedItemExternalLinkProps> = (props) => {
	const media =
		(props.video && <MuxVideo muxVideoAsset={props.video.muxVideo.asset} thumbnailAsset={props.video.thumbnail?.asset} background autoPlay />) ??
		(props.image && <StyledSanityImage asset={props.image.asset} />);

	return (
		<ExternalLinkWrapper href={props.url}>
			<Grid
				overflow="hidden"
				templateColumns="1fr"
				templateRows={
					props.isUniformHeight && {
						base: 'calc(66.36vw - 1.59264rem)',
						sm: 'calc((100vw - 2.4rem)/2.75*0.6636)',
						md: 'calc((100vw - 2.4rem)/2.5*0.6636)',
					}
				}
			>
				{media && (
					<GridItem gridColumn="1/-1" gridRow="1">
						<GridAlignmentWrapper gridHeight={props.isUniformHeight ? 'row' : 'media'}>{media}</GridAlignmentWrapper>
					</GridItem>
				)}

				{/* {props.image && props.image.asset && (
					<StyledSanityImage asset={props.image.asset} />
				)} */}
			</Grid>

			<FeedItemMeta title={props.title} body={props.body} category={props.category} />
		</ExternalLinkWrapper>
	);
};
