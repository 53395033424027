import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';
import React, { PropsWithChildren } from 'react';

export type LinkProps = PropsWithChildren<NextLinkProps & Omit<ChakraLinkProps, 'as'>>;

//  Has to be a new component because both chakra and next share the `as` keyword
export const Link = ({ href, as, replace, scroll = false, shallow, prefetch, children, ...chakraProps }: LinkProps) => {
	return (
		<NextLink passHref={true} href={href} as={as} replace={replace} scroll={scroll} shallow={shallow} prefetch={prefetch}>
			<ChakraLink {...chakraProps}>{children}</ChakraLink>
		</NextLink>
	);
};

export const LinkWrapper: React.FC<LinkProps> = (props) => {
	if (!props.href) return <>{props.children}</>;

	return <Link href={props.href}>{props.children}</Link>;
};
