import * as React from 'react';
import { Asset } from '@lib/types';
import { FeedItemMeta, FeedItemMetaProps } from './FeedItemMeta';
import { LinkWrapper } from '@components/Link';
import { SanityAsset } from '@components/Asset';

export interface FeedItemPostProps extends FeedItemMetaProps {
	href: string;
	image: Asset;
}

export const FeedItemPost: React.FC<FeedItemPostProps> = (props) => {
	return (
		<LinkWrapper href={props.href}>
			{props.image && (
				<div className="home-grid__img">
					<SanityAsset asset={props.image} />
				</div>
			)}
			<FeedItemMeta
				body={props.body}
				category={props.category}
				date={props.date}
				showCategory={props.showCategory}
				showDate={props.showDate}
				title={props.title}
			/>
		</LinkWrapper>
	);
};

FeedItemPost.defaultProps = {
	category: 'News',
};
