import * as React from 'react';
import GridAlignmentWrapper from '@components/GridAlignmentWrapper';
import StyledSanityImage from '@components/SanityImage';
import { Asset } from '@lib/types';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { FeedItemMeta, FeedItemMetaProps } from './FeedItemMeta';
import { LinkWrapper } from '@components/Link';
import { MuxVideo, MuxVideoData } from '@components/modules/VideoModule';
import { SanityImageAsset } from '@sanity/asset-utils';

export interface FeedItemProjectProps extends FeedItemMetaProps {
	hasOverlay?: boolean;
	image: Asset;
	isUniformHeight?: boolean;
	slug: string;
	workRolloverColor?: string;
	workRolloverTextColor?: string;
	video?: {
		muxVideo?: {
			asset?: MuxVideoData;
		};
		thumbnail?: {
			asset?: SanityImageAsset;
		};
	};
}

export const FeedItemProject: React.FC<FeedItemProjectProps> = (props) => {
	const media =
		(props.video && <MuxVideo muxVideoAsset={props.video.muxVideo.asset} thumbnailAsset={props.video.thumbnail?.asset} background autoPlay />) ??
		(props.image && <StyledSanityImage asset={props.image} />);

	return (
		<Box className="grid-project">
			<LinkWrapper href={props.slug}>
				<Grid
					overflow="hidden"
					templateColumns="1fr"
					templateRows={
						props.isUniformHeight && {
							base: 'calc(66.36vw - 1.59264rem)',
							sm: 'calc((100vw - 2.4rem)/2.75*0.6636)',
							md: 'calc((100vw - 2.4rem)/2.5*0.6636)',
						}
					}
				>
					{props.hasOverlay && (
						<GridItem
							_hover={{ opacity: 1 }}
							bg={props.workRolloverColor ?? 'black'}
							color={props.workRolloverTextColor ?? 'white'}
							display={['none', 'initial']}
							gridColumn="1/-1"
							gridRow="1"
							opacity={0}
							overflow="hidden"
							role="group"
							transition="all .2s ease-in-out"
							zIndex="banner"
						>
							<Box p={4} transition="all .2s ease-in-out" transform="translateY(-15%)" _groupHover={{ transform: 'translateY(0)' }}>
								<Text fontSize={{ base: 'sm', md: 'sm', lg: 'md', xl: 'xl' }} noOfLines={{ base: 6, sm: 8, md: 10, lg: 12 }}>
									— {props.body || props.title}
								</Text>
							</Box>
						</GridItem>
					)}
					{media && (
						<GridItem gridColumn="1/-1" gridRow="1">
							<GridAlignmentWrapper gridHeight={props.isUniformHeight ? 'row' : 'media'}>{media}</GridAlignmentWrapper>
						</GridItem>
					)}
				</Grid>
				<FeedItemMeta
					body={props.body}
					category={props.category}
					date={props.date}
					showBody={props.showBody}
					showCategory={props.showCategory}
					showDate={props.showDate}
					title={props.title}
				/>
			</LinkWrapper>
		</Box>
	);
};

FeedItemProject.defaultProps = {
	category: 'Work',
	isUniformHeight: false,
	showCategory: true,
};
