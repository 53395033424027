import * as React from 'react';
import { ComponentFactory } from '@components/components/interactive';
import { FeedItemMeta, FeedItemMetaProps } from './FeedItemMeta';
import { LinkWrapper } from '@components/Link';

export interface FeedItemComponentProps extends FeedItemMetaProps {
	category?: string;
	height: number;
	href: string;
	id: string;
	text: string;
	title: string;
	width: number;
}

export const FeedItemComponent: React.FC<FeedItemComponentProps> = (props) => {
	return (
		<LinkWrapper href={props.href}>
			<ComponentFactory id={props.id} width={props.width} height={props.height} />
			<FeedItemMeta
				body={props.text}
				category={props.category}
				date={props.date}
				showBody={props.showBody}
				showCategory={props.showCategory}
				showDate={props.showDate}
				title={props.title}
			/>
		</LinkWrapper>
	);
};

FeedItemComponent.defaultProps = {
	height: 1080,
	width: 1920,
};
